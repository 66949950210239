<template>
    <div class="pointGoods_detail">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" label-width="80px" size="small">
                <el-form-item label="商品名称">
                    <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item label="商品描述">
                    <el-input v-model="form.description" />
                </el-form-item>
                <el-form-item label="商品类型">
                    <el-input readonly v-model="form.name" />
                </el-form-item>
                <el-form-item label="折扣">
                    <ef-price-input v-model="form.discount" :min="0.1" :max="10" :precision="1" />
                </el-form-item>
                <el-form-item label="可用次数">
                    <ef-price-input v-model="form.canUseTime" :min="1" :max="9999" :precision="0" />
                </el-form-item>
                <el-form-item label="有效时间">
                    <el-date-picker v-model="form.effectiveTime" type="date" value-format="yyyy-MM-dd" />
                </el-form-item>
                <el-form-item label="购买积分">
                    <ef-price-input v-model="form.buyPoint" :min="1" :max="99999" :precision="0" />
                </el-form-item>
                <el-form-item label="库存数量">
                    <ef-price-input v-model="form.stock" :min="1" :max="99999" :precision="0" />
                </el-form-item>
                <el-form-item label="企业">
                    <el-input readonly v-model="form.companyName" />
                </el-form-item>
                <el-form-item label="机构组">
                    <el-input readonly v-model="form.deptGroupName" />
                </el-form-item>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import EfPriceInput from 'components/EfPriceInput';

export default {
    name: 'PointGoodsEdit',
    props: ['form'],
    components: { EfPriceInput },
    data() {
        return {
            member: {
                name: this.form.name,
                mobile: this.form.mobile,
                sex: this.form.sex,
                age: this.form.age,
                provinceCode: this.form.provinceCode,
                cityCode: this.form.cityCode,
                districtCode: this.form.districtCode,
                address: this.form.address,
            },
            url: {
                save: '/member/pointGoods/patchExtend/',
            },
            meta: {
                brands: [],
                categories: [],
            },
        };
    },
    mounted() {},
    methods: {
        handleSave() {
            const _this = this;
            if (!this.form.name) {
                _this.$message.error('请填写商品名称');
                return;
            }
            if (!this.form.description) {
                _this.$message.error('请填写商品描述');
                return;
            }
            if (!this.form.discount) {
                _this.$message.error('请填写折扣');
                return;
            }
            if (!this.form.canUseTime) {
                _this.$message.error('请填写可用次数');
                return;
            }
            if (!this.form.buyPoint) {
                _this.$message.error('请填写购买积分');
                return;
            }
            if (!this.form.stock) {
                _this.$message.error('请填写库存数量');
                return;
            }
            const _params = {
                name: _this.form.name,
                code: _this.form.code,
                goodsType: _this.form.goodsType,
                status: _this.form.status,
                deptGroupCode: _this.form.deptGroupCode,
                companyCode: _this.form.companyCode,
                description: _this.form.description,
                discount: _this.form.discount,
                canUseTime: _this.form.canUseTime,
                buyPoint: _this.form.buyPoint,
                stock: _this.form.stock,
                effectiveTime: Date.parse(_this.form.effectiveTime),
            };
            UrlUtils.PatchRemote(_this, _this.url.save + _this.form.code, _params);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
